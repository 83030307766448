<template>
	<div>

		<mdb-modal v-if="editMode == true" :show="show" @close="show = false">
			<form class="margin-bottom-0">
			<mdb-modal-header>
        <mdb-modal-title>{{ isEdit ? $t('editEvent') : $t('createEvent') }}</mdb-modal-title>
      </mdb-modal-header>
			<mdb-modal-body>

				<mdb-input v-model="editableEvent.title" :label="$t('title')" type="text" :placeholder="$t('eventTitle')" required />

				<div class="md-form">
					<label class="active">{{ $t('dateRange') }}</label>
					<mdb-row>
						<mdb-col>
							<mdb-date-picker-2 :options="dateSelectOptions" inline v-model="editableEvent.start" value="editableEvent.start" :label="$t('startDate')" required />
						</mdb-col>
						<mdb-col>
							<mdb-date-picker-2 :options="dateSelectOptions" inline v-model="editableEvent.end" :label="$t('endDate')" required />
						</mdb-col>
					</mdb-row>
				</div>

				<mdb-select v-model="types" :label="$t('type')" :placeholder="$t('selectType')" @getValue="handleEventTypeChange" required />

				<mdb-select v-model="users" :label="$t('user_plural')" :placeholder="$t('selectUser_plural')" multiple @getValue="handleUsersChange" required />

				<div class="md-form">
					<label class="active">{{ $t('file_plural') }}</label>
					<mdb-row>
						<mdb-col>
							<mdb-file-input multiple sm btnColor="primary" name="files" @getValue="fileChange"/>
						</mdb-col>
					</mdb-row>
				</div>

				<div v-if="isEdit">
					<mdb-row v-for="file in fileInfo" :key="file.id">
						<mdb-col class="col-8">
							<small class="align-self-center">
								<a style="margin-top: 10px" v-auth-href :href="file.url">{{file.name}}</a>
							</small>
						</mdb-col>
						<mdb-col class="col-4">
							<mdb-btn color="red" size="sm" @click.native="showConfirmation('file', file.id)">
								<mdb-icon far icon="trash-alt" class="mr-1" />
								{{ $t('delete') }}
							</mdb-btn>
						</mdb-col>
					</mdb-row>
				</div>

			</mdb-modal-body>

			<mdb-modal-footer>
				<mdb-btn color="primary" size="md" type="submit" @click.native.prevent="isEdit ? handleUpdate() : handleCreate()">
					<mdb-icon far icon="save" class="mr-1" />
					{{ isEdit ? $t('save') : $t('create') }}
				</mdb-btn>
				<mdb-btn color="primary" size="md" @click.native="show = false">
					<mdb-icon far icon="times-circle" class="mr-1" />
					{{ $t('cancel') }}
				</mdb-btn>
				<mdb-btn v-if="isEdit" size="md" color="red" @click.native="showConfirmation('event', '')">
					<mdb-icon far icon="trash-alt" class="mr-1" />
					{{ $t('delete') }}
				</mdb-btn>
			</mdb-modal-footer>

			</form>
		</mdb-modal>

		<mdb-modal v-if="editMode == false" :show="show" @close="show = false">
			<mdb-modal-header>
        <mdb-modal-title>{{ $t('eventInfo') }}</mdb-modal-title>
      </mdb-modal-header>
			<mdb-modal-body>
				<mdb-row>
					<mdb-col md="4">{{ $t('title') }}</mdb-col>
					<mdb-col><p>{{ editableEvent.title }}</p></mdb-col>
				</mdb-row>
				<mdb-row>
					<mdb-col md="4">{{ $t('startDate') }}</mdb-col>
					<mdb-col><p v-if="editableEvent.start">{{ editableEvent.start | moment("DD.MM.YYYY") }}</p></mdb-col>
				</mdb-row>
				<mdb-row>
					<mdb-col md="4">{{ $t('endDate') }}</mdb-col>
					<mdb-col><p v-if="editableEvent.end">{{ editableEvent.end | moment("DD.MM.YYYY")  }}</p></mdb-col>
				</mdb-row>
				<mdb-row>
					<mdb-col md="4">{{ $t('type') }}</mdb-col>
					<mdb-col><p>{{ editableEvent.eventType.title }}</p></mdb-col>
				</mdb-row>
				<mdb-row>
					<mdb-col md="4">{{ $t('user_plural') }}</mdb-col>
					<mdb-col>
						<ul v-if="editableEvent.users && editableEvent.users.length">
							<li v-for="user in editableEvent.users" :key="user.id">{{ user.firstname }} {{ user.lastname }}</li>
						</ul>
						<p v-else>-</p>
					</mdb-col>
				</mdb-row>
				<mdb-row>
					<mdb-col md="4">{{ $t('file_plural') }}</mdb-col>
					<mdb-col>
						<div>
							<div v-for="file in fileInfo" :key="file.id">
								<a v-auth-href :href="file.url">{{file.name}}</a>
							</div>
						</div>
					</mdb-col>
				</mdb-row>
			</mdb-modal-body>
		</mdb-modal>

		<mdb-modal :show="showConfirm" @close="showConfirm = false">
			<mdb-modal-header>
        <mdb-modal-title>{{ $t('confirmation') }}</mdb-modal-title>
      </mdb-modal-header>
			<mdb-modal-body>
				<mdb-row class="text-center">
					<mdb-col v-if="eventOrFile == 'event'">
						<mdb-btn color="red" @click.native.prevent="handleDelete">{{ $t('delete') }}</mdb-btn>
					</mdb-col>
					<mdb-col v-if="eventOrFile == 'file'" >
						<mdb-btn color="red" @click.native="handleDeleteFile(fileToDelete)">{{ $t('delete') }}</mdb-btn>
					</mdb-col>
					<mdb-col>
						<mdb-btn color="primary" @click.native="showConfirm = false">{{ $t('cancel') }}</mdb-btn>
					</mdb-col>
				</mdb-row>
			</mdb-modal-body>
		</mdb-modal>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
	name: "EventModal",
	components: {
	},
	props: {
		event: Object,
		eventTypes: {
			type: Array,
			default: () => []
		},
		eventUsers: {
			type: Array,
			default: () => []
		},
		isEdit: {
      type: Boolean,
      default: false,
		},
		editMode: {
      type: Boolean,
      default: false,
		},
	},
  data: function () {
    return {
			show: false,
			showConfirm: false,
			eventOrFile: '',
			fileToDelete: '',
			types: [],
			users: [],
			editableEvent: {},
			modalId: 'event-modal',
			dateSelectOptions: {
				firstDay: 1,
				week: ['su', 'ma', 'ti', 'ke', 'to', 'pe', 'la'],
				month: ['tammi', 'helmi', 'maalis', 'huhti', 'touko', 'kesä', 'heinä', 'elo', 'syys', 'loka', 'marras', 'joulu']
			}
    }
	},
	computed: {
		...mapState('event', ['fileInfo']),
  },
  methods: {
    ...mapActions('event', ['getEvents', 'updateEvent', 'createEvent', 'deleteEvent', 'getFile', 'getFileInfo', 'deleteFileInfo']),
		handleEventTypeChange(value, text) {
			this.editableEvent.eventTypeId = value
		},
		handleUsersChange(value, text) {
			this.editableEvent.users = value
		},
		fileChange(val) {
      this.editableEvent.files = val
		},
		showConfirmation(value, fileUrl){
			this.showConfirm = true
			this.eventOrFile = value
			this.fileToDelete = fileUrl
		},
    handleUpdate() {
      if ( this.editableEvent.title
				&& this.editableEvent.start
				&& this.editableEvent.end
				&& this.editableEvent.eventTypeId
				&& this.editableEvent.users ) {

				this.updateEvent(this.editableEvent)
					.then(
						() => {
							this.getEvents();
							this.show = false
							this.showConfirm = false
						}, error => {
							console.log(error);
						}
					);
      }
    },
		handleCreate() {
			if ( this.editableEvent.title
				&& this.editableEvent.start
				&& this.editableEvent.end
				&& this.editableEvent.eventTypeId
				&& this.editableEvent.users ) {

				this.createEvent(this.editableEvent)
					.then(
						() => {
							this.getEvents();
							this.show = false
							this.showConfirm = false
						}, error => {
							console.log(error);
						}
					);
			}
		},
		handleDelete() {
			if ( this.editableEvent.title
				&& this.editableEvent.start
				&& this.editableEvent.end
				&& this.editableEvent.eventTypeId
				&& this.editableEvent.users ) {

				this.deleteEvent(this.editableEvent)
					.then(
						() => {
							this.getEvents();
							this.show = false
							this.showConfirm = false
						}, error => {
							console.log(error);
						}
					);
			}
		},
		openFile(fileName) {
			this.getFile(fileName);
		},
		handleDeleteFile(id) {
			this.deleteFileInfo(id)
				.then(
					() => {
						this.getFileInfo(this.editableEvent.id);
						this.showConfirm = false
					}, error => {
						console.log(error);
					}
				);
		},
		reloadEventUsers(){
			let opts = []
			this.eventUsers.forEach((user, i) => {
				if (user.active) {
					var eventUserIds = [];
					if (this.event.users) {
						this.event.users.forEach((user) => {
							eventUserIds.push(user.id);
						});
					}
					let selected = this.event.users && eventUserIds.includes(user.id);
					opts.push({text: user.firstname+' '+user.lastname, value: user.id, selected: selected})
				}
			});
			this.users = opts
		},
		reloadEventTypes(){
			let opts = []
			this.eventTypes.forEach((type, i) => {
				let selected = this.event.eventTypeId && type.id == this.event.eventTypeId
				opts.push({text: type.title, value: type.id, selected: selected})
			});
			this.types = opts
		},
  },
	watch: {
    event: {
      immediate: true,
      handler (eventObj) {
        this.editableEvent = {...eventObj}
				this.reloadEventUsers()
				this.reloadEventTypes()
      }
		},
		fileInfo: {
			immediate: true,
			handler () {
      }
		}
	},
	created() {
		this.getFileInfo(this.editableEvent.id)
	},
	mounted() {
		// event modal:show listener
		this.$aModal.$on('modal:show', modalId => {
			if(this.modalId == modalId){
				this.show = true;
			}
		});
		// event modal:hide listener
		this.$aModal.$on('modal:hide', modalId => {
			if(this.modalId == modalId){
				this.show = false;
			}
		});
	},
};
</script>

<style>
.select-list {
	border:0px;
	outline:0px;
	border-bottom: 1px solid #ced4da;
	margin: .5rem 0 0 0;
	padding: 0;
	color: #666666;
	box-shadow: none;
}
</style>
