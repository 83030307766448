<template>
  <div>
    <div class="text-center">
      <small>
        <span v-for="eventType in eventTypes" :key="eventType.id" class="mr-3">
          <i class="fas fa-circle" :style="{ color: eventType.color }"></i>
          {{ eventType.title }}
        </span>
      </small>
    </div>
    <FullCalendar
      :options="configOptions" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import FullCalendar, { compareNumbers } from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import i18n from '../i18n'
import $ from 'jquery'

export default {
  components: {
    FullCalendar,
  },
  data() {
    return {
      showModal: false
    }
  },
  computed: {
    ...mapGetters('user', ['userResources']),
    ...mapGetters('event', ['eventsWithResources', 'eventTypes']),
    ...mapState('event', ['holidays']),
    configOptions() {
      return {
        plugins: [
          dayGridPlugin,
          interactionPlugin,
          resourceTimelinePlugin,
        ],
        themeSystem: 'bootstrap',
        resourceAreaHeaderContent: i18n.tc('user'),
        resourceAreaWidth: '10%',
        locales: ['fiLocales', 'enLocales'],
        locale: 'fi',
        initialView: 'resourceTimelineMonth',
        buttonText: {
          today: i18n.tc('today')
        },
        slotLabelFormat: {
          weekday: 'short',
          day: 'numeric',
        },
        stickyHeaderDates: true,
        displayEventTime: false,
        eventClick: this.handleEventClick,
        resources: this.visibleUsers(),
        eventSources: [
          // events
          this.eventsWithResources,
          // holidays
          this.holidays,
          // weekends
          [{
            daysOfWeek: [0, 6],
            display: "background",
            unclickable: true,
            className: 'fc-bg-event-weekend'
          }],
        ],
        eventContent: function(arg) {
          // holiday event title
          if (arg.event.extendedProps.type && arg.event.extendedProps.type === 'gHoliday') {
            let holidayTitle = document.createElement('div')
            holidayTitle.innerHTML = arg.event.title;
            $(holidayTitle).addClass('gHoliday-title');
            let arrayOfDomNodes = [ holidayTitle ];
            return { domNodes: arrayOfDomNodes };
          }
        },
      }
    },
  },
  methods: {
    ...mapActions('event', ['createEvent', 'getEvents', 'updateEvents', 'deleteEvent', 'getEventTypes', 'getGoogleCalendarHolidays']),
    ...mapActions('user', ['getUsers']),
    handleEventClick: function(arg) {
      if (!arg.event.extendedProps.unclickable) {
        let event = arg.event.extendedProps.fullEventObject
        this.$emit('updateEvent', event)
      }
    },
    visibleUsers: function() {
      let userArr = []
      this.userResources.forEach((user, x) => {
        if (user.visible == true) {
          userArr.push(user)
        }
      })
      return userArr
    },
  },
  created(){
    this.getGoogleCalendarHolidays();
    this.getUsers();
    this.getEvents();
  },
}
</script>

<style>
  .box {
    height: 10px;
    width: 25px;
    border: 1px solid black;
    clear: both;
    display: inline-block;
  }
  .redBox {
    background-color: rgb(255, 0, 0);
  }
  .greenBox {
    background-color: rgb(0, 255, 0);
  }
  .blueBox {
    background-color: rgb(0, 0, 255);
  }
  .yellowBox {
    background-color: rgb(255, 255, 0);
  }
  .appendText {
    display: inline;
  }
  .fc-toolbar-chunk:nth-child(2){
    align-content: center;
    justify-content: center;
  }
  .fc-timeline-slot-cushion {
    font-size: 10px;
  }
  .fc-day-today {
    background: #CCC !important;
  }
  .fc-bg-event {
    opacity: 1 !important;
    background-color: #EEE !important;
  }
  .gHoliday-title {
    color: rgb(50, 50, 50);
    font-weight: bold;
    font-size: 12px;
    padding: 3px;
  }
</style>
