import moment from 'moment';
import { eventService } from '../../services'
import $ from 'jquery'

// initial state
const state = () => ({
  events: [],
  event: {},
  eventTypes: [],
  fileURL: '',
  fileInfo: [],
  error: {},
  holidays: [],
})

// actions
const actions = {
  getEvents({ commit }) {
    return eventService.getEvents()
      .then(
        events => {
          commit('getSuccess', events)
        },
        error => {
          commit('getFailure', error)
        }
      );
  },
  createEvent({ commit }, event) {
    return eventService.createEvent(event)
      .then(
        event => {
          commit('createSuccess', event)
        },
        error => {
          commit('createFailure', error)
        }
      );
  },
  updateEvent({ commit }, event) {
    var eventUserIds = [];
    event.users.forEach(user => {
      if (typeof user != 'number' && user.id) {
        eventUserIds.push(user.id);
      }
    });
    event.users = eventUserIds.length ? eventUserIds : event.users;
    return eventService.updateEvents(event)
      .then(
        event => {
          commit('updateSuccess', event)
        },
        error => {
          commit('updateFailure', error)
        }
      );
  },
  deleteEvent({ commit }, event) {
    return eventService.deleteEvent(event)
      .then(
        event => {
          commit('deleteSuccess', event)
        },
        error => {
          commit('deleteFailure', error)
        }
      );
  },
  getEventTypes({ commit }) {
    return eventService.getEventTypes()
      .then(
        eventTypes => {
          commit('getTypesSuccess', eventTypes)
        },
        error => {
          commit('getTypesFailure', error)
        }
      );
  },
  getFile({ commit }, fileName) {
    return eventService.getFile(fileName)
      .then(
        fileURL => {
          commit('getFileSuccess', fileURL)
        },
        error => {
          commit('getFileFailure', error)
        }
      );
  },
  getFileInfo({ commit }, id) {
    return eventService.getFileInfo(id)
      .then(
        fileInfo => {
          commit('getFileInfoSuccess', fileInfo)
        },
        error => {
          commit('getFileInfoFailure', error)
        }
      );
  },
  deleteFileInfo({ commit }, id) {
    return eventService.deleteFileInfo(id)
      .then(
        fileInfo => {
          commit('fileInfoDeleteSuccess', fileInfo)
        },
        error => {
          commit('fileInfoDeleteFailure', error)
        }
      );
  },
  getGoogleCalendarHolidays({ commit }) {
    return eventService.getGoogleCalendarHolidays()
      .then(
        holidays => {
          const data = holidays.items;
          // filter results into events
          if (data && data.length) {
            const dates = data.map((item, i) => {
              // if holiday
              if (
                item.description.includes('vapaapäivä') ||
                item.description.includes('holiday')
              ) {
                const timespan = {
                  id: i,
                  title: item.summary,
                  description: item.summary,
                  start: item.start.date,
                  end: item.end.date,
                  editable: false,
                  display: "background",
                  unclickable: true,
                  type: "gHoliday",
                  className: 'fc-bg-event-holiday'
                };
                return timespan;
              } else {
                return {}
              }
            });
            commit('holidaysSuccess', dates)
          } else {
            commit('holidaysSuccess', [])
          }
        },
        error => {
          commit('holidaysError', error)
        }
      );
  },
}

// mutations
const mutations = {
  getSuccess(state, events) {
    state.events = events
  },
  getFailure(state, error) {
    state.error = error
  },
  holidaysSuccess(state, holidays) {
    state.holidays = holidays
  },
  holidaysError(state, error) {
    state.error = error
  },
  createSuccess(state, event) {
    state.event = event
  },
  createFailure(state, error) {
    state.error = error
  },
  updateSuccess(state, event) {
    state.event = event
  },
  updateFailure(state, error) {
    state.error = error
  },
  deleteSuccess(state, event) {
    state.event = event
  },
  deleteFailure(state, error) {
    state.error = error
  },
  getTypesSuccess(state, eventTypes) {
    state.eventTypes = eventTypes
  },
  getTypesFailure(state, error) {
    state.error = error
  },
  getFileSuccess(state, fileURL) {
    state.fileURL = fileURL
  },
  getFileFailure(state, error) {
    state.error = error
  },
  getFileInfoSuccess(state, fileInfo) {
    state.fileInfo = fileInfo
  },
  getFileInfoFailure(state, error) {
    state.error = error
  },
  fileInfoDeleteSuccess(state, fileInfo) {
    state.fileInfo = fileInfo
  },
  fileInfoDeleteFailure(state, error) {
    state.error = error
  },
}

const getters = {
  eventsWithResources: state => {
    let er = []
    state.events.forEach((event, i) => {
      for (var u in event.users) {
        er.push({
          id: event.id,
          resourceId: event.users[u].id,
          title: event.title,
          start: moment(event.start).format('YYYY-MM-DD HH:mm:ss'),
          end: moment(event.end).add(23, 'h').add(59, 'm').format('YYYY-MM-DD HH:mm:ss'),
          backgroundColor: event.eventType.color,
          textColor: event.eventType.textColor,
          fullEventObject: event
        })
      }
    });
    return er;
  },
  fileURL: state => {
    return state.fileURL;
  },
  fileInfo: state => {
    return state.fileInfo;
  },
  eventTypes: state => {
    return state.eventTypes;
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
